/* eslint-disable no-case-declarations */
import { IEvent, IHost, IUser } from "@Interfaces";
import { IInstalment } from "@Modules/eventPage/components/PaymentPlan/IPaymentPlan";
import {
    ACTION_TYPES,
    API_ACTION_TYPES,
    EVENT_TYPE,
    IBookingData,
    QUERY_PARAMS,
    SELECTORS,
    STORAGE_KEYS,
    clearPaymentCache,
    createAction,
    getBookingData,
    getListingIsClass,
    isBrowser,
    isEmpty,
} from "@Utils";
import { getQueryParam } from "repoV2/utils/urls&routing/urlParams";
import { processCustomQuestions } from "@Utils/customQuestions";
import { callPaymentGateway } from "@Utils/payment";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { URL_SEARCH_PARAMS as THANKYOU_URL_SEARCH_PARAMS } from "@Modules/thankyou/constants/thankyou.contants";
import { URL_SEARCH_PARAMS } from "repoV2/constants/urls&routing/urlParams";
import { IUtmParams } from "repoV2/interfaces/IUtmParams";
import {
    getAnalyticsInfo,
    initAndGetEndUserSessionId,
} from "repoV2/utils/common/analytics/analyticsInfo";
import { initAndGetReferrerWithLogic } from "repoV2/utils/common/analytics/referrer";
import { getUtmParams } from "repoV2/utils/common/analytics/utmParams";
import { isInstallmentPaymentType } from "repoV2/utils/common/listing";
import {
    GATEWAYS_ENUM,
    GATEWAYS_SUPPORTED,
    TRANSACTION_STATUSES,
} from "repoV2/constants/payment";
import {
    getLocalStorageItem,
    getSessionStorageItem,
    removeSessionStorageItem,
    setLocalStorageItem,
    setSessionStorageItem,
} from "repoV2/utils/common/storage/getterAndSetters";
import { getReturnUrl } from "repoV2/utils/payment/getReturnUrl";
import { LOCAL_STORAGE_KEYS } from "repoV2/features/Common/modules/Storage/Storage.constants";
import { checkIsStringEmpty } from "repoV2/utils/common/dataTypes/string";
import { QUESTION_PLACEMENT } from "repoV2/features/Listing/Listing.constants";
import { isEvent } from "./utils";

export interface ICachedBookingData {
    listingId: string;
    slot?: string[];
    quantity?: number;
    numberOfCustomers?: number;
    customPrice?: number;
    answers?: IEvent.IPaymentInfo["answers"];
    otherListings?: IEvent.IPaymentInfo["otherListings"];
}

export interface IAvailabilityApiResponse {
    message: string;
    status: number;
    data?: {
        has_user_details_changed: boolean;
        disable_customer_updation?: boolean;
        customer_mismatch_data: {
            // Either existing_email and new_email both exist, or existing_phone and new_phone both exist
            existing_email?: string;
            new_email?: string;

            existing_phone_number?: string;
            new_phone_number?: string;
            new_country_code?: string;
        };
    };
}

export interface IInitiateMetadata {
    stripeCustomerDetails?: { [k: string]: string }[];
    addressDetails?: { [k: string]: string }[];
    customers?: { [k: string]: string }[];
    hasToShowFullAddress?: boolean;
    customPaymentMethod?: "Paypal" | null;
    dontInitiatePayment?: boolean;
    isRebooking?: boolean;
    customPrice?: number;
    customerAddress: string;
    selectedPaymentType?: number;
    initialInstalment?: IInstalment;
    isUserDetailsUpdateOtpVerificationSuccessful?: boolean;
    isCustomerDetailsUpdationAllowed?: boolean;
    headCount?: number;
}

function* computeBookingData(metadata: IInitiateMetadata) {
    const { hostName }: IHost.IStore = yield select(SELECTORS.host);
    const {
        paymentInfo,
        list: eventList,
        selectedEvent,
    }: IEvent.IStore = yield select(SELECTORS.event);

    const eventData = eventList[selectedEvent!];

    const username: IUser.IStore["username"] = yield select(SELECTORS.username);

    // payment info also contains customerAddress
    // on changing page, customerAddress gets lost from reducer (paymentInfo)
    // hence prioritizing metadata's customerAddress
    const bookingData: IBookingData.IReturnType = getBookingData({
        eventData,
        hostName,
        username,
        ...paymentInfo,
        ...(metadata?.customerAddress && {
            customerAddress: metadata?.customerAddress,
        }),
        ...(metadata?.customPaymentMethod === GATEWAYS_ENUM.PAYPAL && {
            gatewaysSupported: [GATEWAYS_ENUM.RAZORPAY],
        }),
        ...(metadata?.headCount && {
            headCount: metadata?.headCount,
        }),
    });
    return bookingData;
}

export function* getAvailability(props: any) {
    const payload = props?.payload;

    // Callback to stop spinner on the event page. To be propagated to all the following sagas
    const paymentErrorCallback: () => void =
        payload?.apiCallArgs?.errorCallback;
    const paymentFinallyCallback: () => void =
        payload?.apiCallArgs?.finallyCallback;

    // using this callback to get data from availability api for managing/updating user exitsing account details
    // for explanation, check handleLogin function in src/components/eventPage/root/OldEventPageTemplate.tsx and src/components/modals/components/UserDetails/index.tsx
    const paymentAvailabilitySuccessCallback: () => void =
        payload?.apiCallArgs?.paymentAvailabilitySuccessCallback;

    const bookingData: IBookingData.IReturnType = yield call(
        computeBookingData,
        payload?.metadata
    );
    const requestId = payload?.response?.data?.request_id;

    yield put(
        createAction(ACTION_TYPES.UTILS.API_CALL, {
            apiActionType: API_ACTION_TYPES.PAYMENT_AVAILABILITY,
            payload: {
                verification_request_id: requestId,
                username: bookingData.username,
                listing_uid: bookingData.listingID, // Base listing
                total_customers: bookingData.numberOfCustomers,
                listing_map: bookingData.listingMap, // Listings details for all
                ...(payload?.metadata?.isRebooking && {
                    is_renewal: true,
                }),
            },
            successCallback: paymentAvailabilitySuccessCallback,
            errorCallback: paymentErrorCallback,
            finallyCallback: paymentFinallyCallback,
            metadata: { ...payload?.metadata }, // Pass along the metadata
        })
    );
}

export function* initiatePayment(props: any) {
    const availabilityApiResponse = props?.payload
        ?.response as IAvailabilityApiResponse;

    const metadata = (props?.payload?.metadata || {}) as IInitiateMetadata;
    const { plans } = yield select(SELECTORS.host);
    const plan_uuid = plans.currentPlanUuid;
    // Address data for stripe, coming from the UserDetails form
    // This is added to the metadata in the handleLogin function in eventPage/root
    const {
        stripeCustomerDetails = [],
        hasToShowFullAddress = false,
        customPaymentMethod,
        dontInitiatePayment,
        isRebooking,
        addressDetails = [],
        customers,
        isUserDetailsUpdateOtpVerificationSuccessful,
        isCustomerDetailsUpdationAllowed,
    } = metadata;

    if (dontInitiatePayment) {
        return;
    }

    // stop payment flow if one the user account details already exist and ask if the user wants to update thier account details
    if (
        isCustomerDetailsUpdationAllowed &&
        availabilityApiResponse.data?.has_user_details_changed &&
        !isUserDetailsUpdateOtpVerificationSuccessful
    )
        return;

    // Callback to stop spinner on the event page. To be propagated to all the following sagas
    // TODO: Move to metadata.
    // TODO: Create chains of sagas that have callbacks triggered at different stages, all of which can be mentioned on the initial call
    const paymentErrorCallback = props?.payload?.apiCallArgs?.errorCallback;
    const paymentFinallyCallback = props?.payload?.apiCallArgs?.finallyCallback;
    const bookingData: IBookingData.IReturnType = yield call(
        computeBookingData,
        metadata
    );
    // Storing user id in localstorage as will be used for post booking questions after payment and whatsapp redirection @quashid
    setLocalStorageItem(STORAGE_KEYS.CUSTOMER_UUID, bookingData.username);

    // Whether the payment is for a custom payment price
    const isCustomPrice: boolean = props?.payload?.metadata?.paySelf ?? false;
    // The total price to be paid INCLUDING taxes
    const customPriceTotal: number | null = metadata?.customPrice ?? null;

    const {
        discount: { discountCode, discountError },
        list: eventList,
        selectedEvent,
        paymentInfo: {
            slots,
            quantity,
            isDirectBooking,
            customPrice,
            answers,
            thumbnails,
            otherListings,
        }, // customPrice does NOT INCLUDE taxes
    }: IEvent.IStore = yield select(SELECTORS.event);
    const usernameList: IUser.IStore["usernameList"] = yield select(
        SELECTORS.usernameList
    );

    // for multiple after booking questions map
    setLocalStorageItem(STORAGE_KEYS.USER_NAME_LIST, usernameList);

    const eventData = eventList[selectedEvent!];
    const selectedOfferingVariation = eventData?.selectedOfferingVariation;
    if (!eventData) return;
    const eventType: number = eventData?.type;

    // Is a Class type event, <one-on-one class> or <group class>
    const isClassType = getListingIsClass(eventType);

    // // Is of No Schedule type event, <no schedule>, <recorded content>, or <merchandise>
    // // NOTE: Use when params need to be passed to the following type of events
    // const isNoScheduleType = [
    //     EVENT_TYPE.NO_SCHEDULE,
    //     EVENT_TYPE.RECORDED_CONTENT,
    //     EVENT_TYPE.MERCHANDISE,
    // ].includes(eventType);

    // Is of Merchandise type event, <merchandise>
    const isMerchandise = [EVENT_TYPE.MERCHANDISE].includes(eventType);

    // Is an event with slots, <appointments> or <workshops>
    const isSlotType = [
        EVENT_TYPE.APPOINTMENTS,
        EVENT_TYPE.WORKSHOPS,
        EVENT_TYPE.WEBINAR,
    ].includes(eventType);

    // Saving data to session storage for the purpose of retrying payment
    setSessionStorageItem(STORAGE_KEYS.BOOKING_DATA, {
        listingId: selectedEvent,
        numberOfCustomers: bookingData.numberOfCustomers,
        ...((isSlotType || isClassType) && {
            slots,
        }),
        ...(isMerchandise && {
            quantity,
        }),
        ...(isCustomPrice && {
            customPrice,
        }),
        ...(Object.keys(otherListings).length > 0 && {
            otherListings,
        }),
        ...(Object.keys(answers).length > 0 && {
            answers,
        }),
    } as ICachedBookingData);

    if (hasToShowFullAddress) {
        setSessionStorageItem(
            STORAGE_KEYS.STRIPE_CUSTOMER_DETAILS,
            stripeCustomerDetails
        );
    }

    if (discountCode && !discountError) {
        setSessionStorageItem(STORAGE_KEYS.DISCOUNT_CODE, discountCode);
    } else {
        // To remove any previously cached discount codes
        removeSessionStorageItem(STORAGE_KEYS.DISCOUNT_CODE);
    }
    const utmDetails: IUtmParams & { referer?: string } = getUtmParams();

    const referer = initAndGetReferrerWithLogic();

    if (referer) {
        utmDetails.referer = referer;
    }

    const returnUrl: string | null = getReturnUrl({
        // For direct booking, we will just show the payment status modal.
        isDirectBooking,
        // For custom price, we need to carry over the custom price...
        customPrice: customPriceTotal,
        // ...and the event ID for the analytics call
        eventId: eventData?.seo_preview?.url_slug || eventData?.uuid,
    });

    const questions = eventData?.q_and_a || [];
    const preBookingQuestions = questions.filter(
        que => que.question_placement === QUESTION_PLACEMENT.BEFORE_BOOKING
    );

    const payloadDiscountCode =
        discountCode && !discountError ? discountCode : "";

    const dynamicLinkUuid = getQueryParam(QUERY_PARAMS.DYNAMIC_LINK) || "";

    interface PayloadListingMap {
        quantity?: number;
        head_count?: number;
        slot_uids?: string[];
        class_uid?: string;
        discount_code?: string;
        transaction_answers?: any;
    }

    const payloadListingMap: { [key: string]: PayloadListingMap } = {
        ...bookingData.listingMap,
        [bookingData.listingID]: {
            ...bookingData.listingMap[bookingData.listingID],
            discount_code: payloadDiscountCode,
            // Answers to custom questions
            ...(!isEmpty(preBookingQuestions) && {
                transaction_answers: Object.assign(
                    {},
                    ...Object.values(answers || {}).map((userAnswer, i) => ({
                        [usernameList[i]]: processCustomQuestions(
                            preBookingQuestions,
                            userAnswer,
                            thumbnails?.[i + 1]
                        ),
                    }))
                ),
            }),
            ...(isInstallmentPaymentType(metadata?.selectedPaymentType) && {
                payment_type: metadata?.selectedPaymentType,
                installment_uuid: metadata?.initialInstalment?.uuid,
            }),
            ...(selectedOfferingVariation && {
                offering_variant_uuid: selectedOfferingVariation.uuid,
            }),
            ...(dynamicLinkUuid && {
                dynamic_link_id: dynamicLinkUuid,
            }),
        },
    };

    /**
     * Fetching user IP details required for sending in Payload for analytics
     */
    const analyticsInfo = getAnalyticsInfo();

    let _discountCode: string | undefined;

    // Check if discount_code is present in any object
    Object.values(payloadListingMap).forEach(obj => {
        if (obj.discount_code) {
            _discountCode = obj.discount_code;
        }
    });

    // Apply the discount_code to all other objects
    // done for upsell
    Object.values(payloadListingMap).forEach(obj => {
        if (!obj.discount_code) {
            // eslint-disable-next-line no-param-reassign
            obj.discount_code = _discountCode;
        }
    });

    const affiliateUuid = getLocalStorageItem(
        LOCAL_STORAGE_KEYS.AFFILIATE_UUID
    );

    const paymentPayload = {
        username: bookingData.username,
        customers,
        session_id: initAndGetEndUserSessionId(), //  Sending the analytics session ID for tracking
        community_listing_id:
            getQueryParam(URL_SEARCH_PARAMS.COMMUNITY_ID) || "",
        thankyou_page_uuid:
            getQueryParam(THANKYOU_URL_SEARCH_PARAMS.THANKYOU_PAGE_ID) || "",
        utm_details: utmDetails,
        return_url: returnUrl,
        gateways_supported: bookingData.gatewaysSupported || GATEWAYS_SUPPORTED,
        listing_id: bookingData.listingID,
        listing_map: payloadListingMap,
        analytics_info: analyticsInfo,

        ...(!checkIsStringEmpty(affiliateUuid) && {
            affiliate_uuid: affiliateUuid,
        }),

        ...(customPaymentMethod === GATEWAYS_ENUM.PAYPAL && {
            is_paypal_order: true,
        }),

        ...(hasToShowFullAddress && {
            address_details: Object.assign(
                {},
                ...stripeCustomerDetails.map((customerDetails, i) => ({
                    [usernameList[i]]: customerDetails,
                }))
            ),
        }),

        ...(isMerchandise && {
            address_details: Object.assign(
                {},
                ...addressDetails.map((customerDetails, i) => ({
                    [usernameList[i]]: customerDetails,
                }))
            ),
        }),

        // Keys for universal payment
        ...(isCustomPrice && {
            pay_self: true,
            price_to_pay: customPrice,
        }),

        // Upsell: Enabled && Selected
        ...(eventData?.upsell_exists &&
            bookingData.otherListings.length > 0 && {
                is_upsell_order: true,
                other_listings: bookingData.otherListings,
                upsell_type: 1, // todo: @ishtiaq differentiate between upsell and thankyou and update this logic
            }),

        // Multiple booking
        // Removed the first element if any as that user has been
        // already accounted for as the primary transacting user
        other_customers: usernameList.filter((x, i) => i !== 0),
        // Whether this payment is being made for a plan
        ...((getQueryParam(QUERY_PARAMS.PLAN_ID) || plan_uuid) && {
            dropdown_plan: getQueryParam(QUERY_PARAMS.PLAN_ID) || plan_uuid,
        }),

        // Customer address
        ...(bookingData?.customerAddress &&
            !isMerchandise && {
                customer_address: bookingData?.customerAddress,
            }),

        // TODO: Send theme color for razorpay customization

        ...(isRebooking && {
            is_renewal: true,
        }),
    };

    yield put(
        createAction(ACTION_TYPES.UTILS.API_CALL, {
            apiActionType: API_ACTION_TYPES.PAYMENT_INITIATE,
            payload: {
                ...paymentPayload,
            },
            errorCallback: paymentErrorCallback,
            finallyCallback: paymentFinallyCallback,
            successCallback: callPaymentGateway({
                eventData,
                returnUrl,
            }),
        })
    );

    yield;
}

export function* loadEventData() {
    if (isBrowser()) {
        // Load the appropriate payment retry data into the store
        if (
            getQueryParam(QUERY_PARAMS.TRANSACTION_ID) &&
            getQueryParam(QUERY_PARAMS.TRANSACTION_STATUS) &&
            Number(getQueryParam(QUERY_PARAMS.TRANSACTION_STATUS)) &&
            Object.values(TRANSACTION_STATUSES).includes(
                Number(getQueryParam(QUERY_PARAMS.TRANSACTION_STATUS))
            )
        ) {
            const { list: eventList, selectedEvent }: IEvent.IStore =
                yield select(SELECTORS.event);
            if (!selectedEvent) return;

            const eventData = eventList[selectedEvent];
            const selectedOfferingVariation =
                eventData?.selectedOfferingVariation;

            const paymentStatus: number = Number(
                getQueryParam(QUERY_PARAMS.TRANSACTION_STATUS)
            );
            if (
                [
                    TRANSACTION_STATUSES.SUCCESS,
                    TRANSACTION_STATUSES.PROCESSING,
                ].includes(paymentStatus)
            ) {
                clearPaymentCache();
            }

            if (paymentStatus === TRANSACTION_STATUSES.FAILURE) {
                // Data for selected slots
                try {
                    const savedBookingData: ICachedBookingData =
                        getSessionStorageItem(STORAGE_KEYS.BOOKING_DATA) || {};
                    // If the saved slot data is valid, then load it up into the root component's
                    // slots react hook state. Each schedule display component will take care of
                    // further loading it into their UI form controls. [Check out ScheduleSelector]
                    const isValidKey = (k: string): boolean =>
                        [
                            "listingId",
                            "slots",
                            "quantity",
                            "customPrice",
                            "answers",
                            "otherListings",
                            "numberOfCustomers",
                        ].includes(k);

                    if (
                        savedBookingData &&
                        "listingId" in savedBookingData &&
                        savedBookingData?.listingId === selectedEvent &&
                        typeof savedBookingData === "object" &&
                        // Check if any of the valid keys exist in the object
                        Object.keys(savedBookingData).some(isValidKey)
                    ) {
                        yield put(
                            createAction(
                                ACTION_TYPES.EVENT.UPDATE_BOOKING_DATA,
                                {
                                    ...savedBookingData,
                                }
                            )
                        );
                    }
                } catch (error) {
                    alert(
                        "An error occurred while retrying your payment. Please re-enter your details and try again."
                    );
                    // eslint-disable-next-line no-console
                    console.log("Payment retry error", error);
                    removeSessionStorageItem(STORAGE_KEYS.BOOKING_DATA);
                }

                // TODO: Check for upsell before this API call
                // Discount code that may have been used
                const cachedDiscountCode: string = getSessionStorageItem(
                    STORAGE_KEYS.DISCOUNT_CODE
                );
                const discountCodeType: IEvent.IAppliedDiscountCodeType =
                    getSessionStorageItem(STORAGE_KEYS.DISCOUNT_CODE_TYPE);
                const loyaltyDiscountEmail = getSessionStorageItem(
                    STORAGE_KEYS.LOYALTY_DISCOUNT_EMAIL
                );
                if (cachedDiscountCode) {
                    yield put(
                        createAction(ACTION_TYPES.UTILS.API_CALL, {
                            apiActionType: API_ACTION_TYPES.APPLY_DISCOUNT,
                            payload: {
                                code: cachedDiscountCode.trim().toUpperCase(),
                                listing_uuid: eventData?.uuid,
                                currency: eventData?.currency_name,
                                ...(discountCodeType === "loyalty" && {
                                    customer_email: loyaltyDiscountEmail,
                                }),
                                ...(selectedOfferingVariation && {
                                    offering_variant_uuid:
                                        selectedOfferingVariation.uuid,
                                }),
                            },
                            errorCallback: () => {
                                removeSessionStorageItem(
                                    STORAGE_KEYS.DISCOUNT_CODE
                                );
                                removeSessionStorageItem(
                                    STORAGE_KEYS.DISCOUNT_CODE_TYPE
                                );
                                removeSessionStorageItem(
                                    STORAGE_KEYS.LOYALTY_DISCOUNT_EMAIL
                                );
                            },
                        })
                    );
                }
            }
        } else {
            clearPaymentCache();
        }
    }
    yield;
}

export default function* eventSaga() {
    yield takeEvery(ACTION_TYPES.EVENT.CHECK_AVAILABILITY, getAvailability);
    yield takeEvery(ACTION_TYPES.EVENT.UPDATE_AVAILABILITY, initiatePayment);
    if (isEvent()) {
        yield call(loadEventData);
    }
}
